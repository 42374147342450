import ReservationWidget from './widget-control/ReservationWidget';
import Device from '../src/utility/device/device';
import ReservationWidgetMobile from './widget-control/ReservationWidgetMobile';
import { isMobile } from 'react-device-detect';

function App(props: any) {
  return (
    <><Device isMobile={isMobile}>
      {({ isMobile }) => {
        if (isMobile)
          return <ReservationWidgetMobile props={props} />;
          return <>
            <ReservationWidget props={props} />
          </>
      }}
    </Device></>
  )
}

export default App