import _ from "lodash";
import { locationTypeEnum } from "../utility/enum/locationTypeEnum";
import { PromotionEnumKey } from "../utility/enum/promotionEnumKey";


export const formattedDate =(date:any) => {
  const [year, month, day] = date?.split('-') ?? [];
  if(year == null || month == null || day == null) return date;
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
}

export const getDestinationKey = (key: any) => {
  key = parseInt(key);
  switch(key) {
    case locationTypeEnum.country:
      return 'countryId';
    case locationTypeEnum.city:
      return 'cityId';
    case locationTypeEnum.property:
      return 'propertyId'
    default :
      return 'pincode'
  }
}

export   const createQueryString = (data: any) => {
  return Object.keys(data).filter(x => data[x] !== false).map(key => {
    let val = data[key]
    if (val === false) return ''
    if (val !== null && typeof val === 'object' && Object.keys(val).length > 0 && !Array.isArray(val)) val = createQueryString(val)
    if(key=='chainID' || key=='brandID'){
      return;
    }
    if(key=='destination' ){
      var newVal = val.split('&');
      val= `${newVal[0]}&${newVal[2]}`;
    }
    if(key=='checkInDate' || key == 'checkOutDate') {
      val = formattedDate(val);
    }
    if (Array.isArray(val) && (key === 'guest')) {
      const arrayParams = new Array();
      val.forEach(obj => {
        switch (obj?.roomCount) {
          case 1: {
            if (obj.adultCount > 0)
              arrayParams.push('ac1='+ obj.adultCount);
            if (obj.childCount > 0)
              arrayParams.push('cc1='+ obj.childCount);
            break;
          }
          case 2: {
            if (obj.adultCount > 0)
              arrayParams.push('ac2='+ obj.adultCount);
            if (obj.childCount > 0)
              arrayParams.push('cc2='+  obj.childCount);
            break;
          }
          case 3: {
            if (obj.adultCount > 0)
              arrayParams.push('ac3='+  obj.adultCount);
            if (obj.childCount > 0)
              arrayParams.push('cc3='+  obj.childCount);
            break;
          }
          case 4: {
            if (obj.adultCount > 0)
              arrayParams.push('ac4='+  obj.adultCount);
            if (obj.childCount > 0)
              arrayParams.push('cc4='+  obj.childCount);
            break;
          }
          case 5: {
            if (obj.adultCount > 0)
              arrayParams.push('ac5='+  obj.adultCount);
            if (obj.childCount > 0)
              arrayParams.push('cc5='+  obj.childCount);
            break;
          }

          default:
            break;
        }
      });
      val = `${arrayParams}`;
      return `${key}=[${encodeURIComponent(`${val}`.replace(/\s/g, '_'))}]`
    }
    return `${key}=${encodeURIComponent(`${val}`.replace(/\s/g, '_'))}`
  }).join('&')
}
export  const bookNowHandler = (values: any, setToastObj: any, promotionData: any, destinationList: any
  , bookingURL: any, brandID: any, chainID: any, params:any, openinNewTab: boolean) => {
  if(!values.destination?.id) {
    setToastObj({
      toastState: true,
      toastMessage: 'Destination is required!'
    })
  }
  else {
    let catId = promotionData?.find((x: { key: PromotionEnumKey; }) => x.key === values?.promotion?.promotionType)?.promoCatId;
    const subCatId = promotionData?.find((x: { key: PromotionEnumKey; }) => x.key === PromotionEnumKey.contractedRate)?.subCategories.find((y: { key: any; }) => y.key === values?.promotion?.promotionType)?.promoScId
    if(subCatId) {
      catId = promotionData?.find((x: { key: PromotionEnumKey }) => x.key === PromotionEnumKey.contractedRate)?.promoCatId;
    }
    const requestCopy = _.cloneDeep(values);
    const request = {
      ...requestCopy,
      promotion: {
        ...requestCopy.promotion,
        catId: catId,
        subCatId: subCatId ? subCatId : '',
        promotionCode1: requestCopy.promotion?.promotionCode1 ? requestCopy.promotion?.promotionCode1 : '',
        promotionCode2: requestCopy.promotion?.promotionCode2 ? requestCopy.promotion?.promotionCode2 : '',
      }
    }
    // Check if the promotionType is "REGULARRATE" and remove the promotion object if true
    if (request?.promotion?.promotionType === "REGULARRATE") {
      delete request.promotion;
    }
    let key = getDestinationKey(values.destination.type);
    const isSingleProperty = destinationList.filter((x: { [x: string]: string; }) => x[key] === values.destination.id).length <= 1;
    const query = createQueryString(request);
    let ibeBookingUrl =bookingURL+'/reservation?bID=' + brandID + '&cID=' + chainID + '&' + query.replace('hC=', '') ;
    if(isSingleProperty && Number(values.destination.type)==2){
      ibeBookingUrl =bookingURL+'/reservation?bID=' + brandID + '&cID=' + chainID + '&' + query.replace('hC=', '') + '&roomPropertyID='+values.destination.id+'';
    }
    if((window as any)?.dlyx?.dlyx_xs){
      ibeBookingUrl += '&dlyx_xs=' + (window as any)?.dlyx?.dlyx_xs;
    }
    if(params.length>1 && params[1]=="DEMAND_CALENDAR"){
      ibeBookingUrl += '&DEMAND_CALENDAR';
    }
    //console.log({ibeBookingUrl})
    // ADDED FOR SAFARI BROWSER
    setTimeout(() => {
      if (openinNewTab) {
        window.open(ibeBookingUrl.replace('&&&', '&'), '_blank');
      } else {
        window.location.href = ibeBookingUrl.replace('&&&', '&');
      }
    })

  }
}