import { useEffect, useState } from 'react';
import { Box, ClickAwayListener } from "@mui/material";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
// import scrollStyle from './addRoom.module.css';

interface RoomObjType {
    adult: number
    child: number
}
export default function AddRoom(props: any) {
    const {setFieldValue, maxChildAge, fontColor, booknowbuttoncolor, booknowbuttonhovercolor, setEventType, eventType, ishome} = props
    const [open, setOpen] = useState(false);
    const [roomObj, setRoomObj] = useState<[any]>([{ adult: 2, child: 0 }]);
    const [adult, setAdult] = useState<number>(2);
    const [child, setChild] = useState<number>(0);
    const [boxHidden, setBoxHidden] = useState(false);

    const handleCloseFilter = () => {
        // if(open && eventType!="addRoomClick"){
        //     setEventType("");
        // }
        setOpen(false);
    }

    const handleFilterOpen = () => {
        setOpen(prevState => !prevState);
        dropdownPosition();
    };

    const removeRoom = () => {
        const newRoomObj: [RoomObjType] = [...roomObj];
        if (newRoomObj.length > 1) {
            newRoomObj.pop()
        }
        setRoomObj(newRoomObj);

    }

    const changeCounter = (isIncrement: boolean, index: number, type: string) => {
        const newRoomObj: any = [...roomObj];
        if (isIncrement)
            newRoomObj[index][type] = newRoomObj[index][type] + 1
        else {
            newRoomObj[index][type] = newRoomObj[index][type] - 1
        }

        setRoomObj(newRoomObj);
    }

    const dropdownPosition = () =>{
        const boxElement = document.getElementById('IBE_AddGuest_Pos');
        if (!boxElement) return;
            const boxPosition = boxElement.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            if (boxPosition.bottom < 0 || (boxPosition.top + boxPosition.bottom) > windowHeight) {
                setBoxHidden(true);
            }
            else {
                setBoxHidden(false);
        }
    }

    const AddRoom = () => {
        const newRoomObj: [RoomObjType] = [...roomObj];
        newRoomObj.push({ adult: 2, child: 0 });
        setRoomObj(newRoomObj)
    }

    useEffect(() => {
        setAdult( roomObj.reduce((n, {adult}) => n + adult, 0));
        setChild( roomObj.reduce((n, {child}) => n + child, 0));
        updateData()
    }, [roomObj,adult,child])

    const updateData = () => {
        const widgetDataCopy: any =  [];

        roomObj.map((data: any, index: number): any => {
                const widgetDataObj = {
                    roomCount: index+1,
                    adultCount: data?.adult,
                    childCount: data?.child
                }
                return widgetDataCopy.push(widgetDataObj);
            });

        setFieldValue('guest', widgetDataCopy, true);
        // handleCloseFilter();
    }

    const setTableData = {
        removeBorder: {
            borderBottom: 'none !important',
            border: 'none !important'
        },
        plusIconPadding: {
            padding: '16px 16px 5px 16px !important'
        },
        getSameLine: {
            'display': 'flex'
        },
        marginRight: {
            'margin-right': '10px'
        },
        fontBold: {
            'font-size': '14px',
            'font-weight': 'bold',
            'color': '#17202B'
        },
        roomText: {
            color: '#17202B',
            opacity: '56%',
            fontSize: '14px',
            textTransform: 'uppercase',
            display: 'inline-block',
            fontWeight: '700',
            paddingLeft: '15px',
            marginBottom: '0px'
        },
        setAge: {
            color: '#767B82',
            fontSize: '12px',
            marginTop: '5px'
        },
        horizontalLine: {
            border: '1px solid #707070',
            opacity: '0.2',
            marginTop: '10px',
            marginBottom: '10px'
        },
        paddLeft: {
            paddingLeft: '16px !important',
        },
        paddingRight2: {
            paddingRight: '2px !important'
        }
    }

    useEffect(() => {
        const handleScroll = () => {
            dropdownPosition();
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);

        return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleScroll);
        };
    }, []);

    useEffect(() => {
        if(eventType == '' && open){
            setOpen(false);
        }
    }, [eventType]);

    return (
        <>
            <ClickAwayListener onClickAway={handleCloseFilter}>
                <Box component="div" className={ishome?'addRoomHome':'addRoom'} >
                    <Box component="div" id='IBE_AddGuest_Pos' sx={{ textWrap: 'nowrap', color: `${fontColor} !important` }} onClick={() => handleFilterOpen()}>
                        {roomObj.length} {roomObj.length > 1 ? 'Rooms' : 'Room'}, {adult} {adult > 1 ? 'Adults' : 'Adult'}
                    </Box>
                    <Box style={{ position: 'relative', marginTop: '15px', marginLeft: "-20px", maxHeight: '494px', overflowY:'auto', boxShadow: '0 3px 6px #00000029', borderRadius: '5px', width:'max-content', display: open ? 'block':'none', zIndex:999, backgroundColor: '#fff', cursor: 'auto' }}>
                        <Box sx={{ p: '20px 15px 10px', marginTop: '14px' }}>
                            <Box component="div" >
                                {
                                    roomObj.map((data: any, index: number): any => {
                                        return (
                                            <>
                                                <Box key={index+'_roomIndex'} component="p" sx={setTableData.roomText}>ROOM {index + 1}</Box>
                                                <TableContainer>
                                                    <Table sx={{ mb: '0px !important' }}>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell width="130px" sx={[setTableData.removeBorder, setTableData.paddLeft, setTableData.paddingRight2]}>
                                                                    <Box component="div" sx={setTableData.fontBold}>ADULTS</Box>
                                                                    <Box component="span" sx={setTableData.setAge}>Ages {maxChildAge+1} or above</Box>
                                                                </TableCell>
                                                                <TableCell sx={[setTableData.removeBorder, setTableData.getSameLine]}>
                                                                    {(data?.adult > 1) && <Box component="span" sx={{
                                                                        color: booknowbuttoncolor ? booknowbuttoncolor : '#2753EB', cursor: 'pointer', marginRight: '10px',
                                                                        '&:hover': { 'color': booknowbuttonhovercolor ? booknowbuttonhovercolor : '#4f5f71' }
                                                                    }} onClick={() => changeCounter(false, index, 'adult')}>
                                                                        <RemoveCircleIcon sx={{ fontSize: '21px' }}></RemoveCircleIcon>
                                                                    </Box>}
                                                                    {(data?.adult === 1) && <Box component="span" sx={{
                                                                        color: '#4f5f71',
                                                                        marginRight: '10px'
                                                                    }} >
                                                                        <RemoveCircleIcon sx={{ fontSize: '21px' }}></RemoveCircleIcon>
                                                                    </Box>}
                                                                    <Box component="span" sx={{ marginRight: '10px', fontSize: '14px', fontWeight: 'bold',width:'12px', mt: '1px' }}>{data?.adult}</Box>
                                                                    {data?.adult < 25 && <Box component="span" sx={{
                                                                        color: booknowbuttoncolor ? booknowbuttoncolor : '#2753EB', cursor: 'pointer', marginRight: '10px',
                                                                        '&:hover': { 'color': booknowbuttonhovercolor ? booknowbuttonhovercolor : '#4f5f71' }
                                                                    }} onClick={() => changeCounter(true, index, 'adult')}>
                                                                        <AddCircleIcon sx={{ fontSize: '21px' }}></AddCircleIcon>
                                                                    </Box>}
                                                                    {(data?.adult > 24) && <Box component="span" sx={{
                                                                        color: '#4f5f71', marginRight: '10px'
                                                                    }} >
                                                                        <AddCircleIcon sx={{ fontSize: '21px' }}></AddCircleIcon>
                                                                    </Box>}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell width="130px" sx={[setTableData.removeBorder, setTableData.paddLeft, setTableData.paddingRight2]}>
                                                                    <Box component="div" sx={setTableData.fontBold}>CHILDREN</Box>
                                                                    <Box component="span" sx={setTableData.setAge}>Ages 0-{maxChildAge}</Box>
                                                                </TableCell>
                                                                <TableCell sx={[setTableData.removeBorder, setTableData.getSameLine]}>
                                                                    {data?.child >0 && <Box component="span" sx={{
                                                                        color: booknowbuttoncolor ? booknowbuttoncolor : '#2753EB', cursor: 'pointer', marginRight: '10px',
                                                                        '&:hover': { 'color': booknowbuttonhovercolor ? booknowbuttonhovercolor : '#4f5f71' }
                                                                    }} onClick={() => changeCounter(false, index, 'child')}>
                                                                        <RemoveCircleIcon sx={{ fontSize: '21px' }}></RemoveCircleIcon>
                                                                    </Box>}
                                                                    {data?.child === 0 && <Box component="span" sx={{
                                                                        color: '#4f5f71',
                                                                        marginRight: '10px'
                                                                    }} >
                                                                        <RemoveCircleIcon sx={{ fontSize: '21px' }}></RemoveCircleIcon>
                                                                    </Box>}
                                                                    <Box component="span" sx={{ marginRight: '10px', mt: '1px', fontSize: '14px', fontWeight: 'bold',width:'12px' }}>{data?.child}</Box>
                                                                    {data?.child < 24 && <Box component="span" sx={{
                                                                        color: booknowbuttoncolor ? booknowbuttoncolor : '#2753EB', cursor: 'pointer', marginRight: '10px',
                                                                        '&:hover': { 'color': booknowbuttonhovercolor ? booknowbuttonhovercolor : '#4f5f71' }
                                                                    }} onClick={() => changeCounter(true, index, 'child')}>
                                                                        <AddCircleIcon sx={{ fontSize: '21px' }}></AddCircleIcon>
                                                                    </Box>}
                                                                    {data?.child > 23 && <Box component="span" sx={{
                                                                        color: '#4f5f71', marginRight: '10px'
                                                                    }} >
                                                                        <AddCircleIcon sx={{ fontSize: '21px' }}></AddCircleIcon>
                                                                    </Box>}
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>

                                                {index > 0 && index === roomObj.length - 1 && <Box component="p" onClick={removeRoom} sx={{
                                                    color: booknowbuttoncolor ? booknowbuttoncolor : '#2753EB', fontSize: '14px',
                                                    cursor: 'pointer', textAlign: 'right', mt: '0px', mr: '15px'
                                                }}>Remove room
                                                </Box>
                                                }
                                                <hr style={setTableData.horizontalLine} />
                                            </>
                                        )
                                    }

                                    )}
                            </Box>
                            {roomObj.length < 5 && <Box component="p" onClick={() => AddRoom()} sx={{
                                color: booknowbuttoncolor ? booknowbuttoncolor : '#2753EB', fontSize: '14px',
                                cursor: 'pointer', textAlign: 'right', mt: '20px', mr: '15px'
                            }}>Add another room
                            </Box>}
                        </Box>
                    </Box>
                </Box>
            </ClickAwayListener>
        </>
    )
}